.change-password {
  &-gnav {
    .cms-password-field {
      &__info {
        margin-#{$ldirection}: 43%;
        margin-top: 0;
        &:before {
          top: 40%;
          right: 40%;
        }
      }
    }
  }
}
