@import 'checkout-helpful-links';

.checkout {
  .left.checkout__content {
    #viewcart-panel {
      .cart-items.products {
        .total_column.sub__total {
          float: right;
          margin: 12px 0;
        }
        @media (max-width: 768px) {
          .cart-header {
            .price {
              width: 20%;
            }
          }
        }
        .cart-item.product {
          .cart-item {
            &__thumb-image {
              padding: 0 17%;
              display: block;
              width: 100%;
            }
            @media (max-width: 768px) {
              &__price {
                width: 20%;
              }
            }
          }
        }
      }
    }
    #viewcart-panel,
    #bottom-viewcart-buttons {
      .viewcart-buttons-panel {
        .continue-buttons {
          .disabled.continue-checkout {
            background-color: $color-light-gray;
          }
        }
        a.continue-checkout {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    .checkout-buttons-container {
      margin-bottom: 60px;
      .checkout-buttons.disabled {
        background-color: $color-light-gray;
      }
      input.form-submit {
        float: right;
      }
    }
    .shipping-panel {
      #review-address-info {
        .transaction-panel-details {
          margin-top: 20px;
          .transaction-item {
            float: left;
            margin: 0 28px 28px 0;
            position: relative;
            width: 290px;
            word-wrap: break-word;
            &.billing_container {
              margin-right: 0;
              h4.checkout__subtitle {
                width: 245px;
              }
            }
            &.shipping_container {
              clear: none;
              margin-right: 0;
            }
            dt {
              a.change_link {
                position: absolute;
                top: 10px;
                right: 0;
              }
            }
          }
        }
      }
    }
    .checkout-header {
      margin: 25px 0;
    }
    #shipping-page-info-panel,
    #billing-address-info-panel {
      #bill_to_shipping_container {
        margin-bottom: 11px;
      }
      #shipping-info-panel,
      #billing-info-panel {
        .address-container {
          float: left;
          margin: 0 4em 0.8em 1.5em;
          width: 15em;
          address {
            font-style: normal;
          }
        }
        .address_controls {
          float: left;
          #choose-address {
            margin-top: 0.8em;
            width: 24em;
          }
        }
      }
      #emailpromo-info-panel {
        float: left;
        margin-top: 20px;
      }
    }
    .payment-info-panel {
      .payment_method_container {
        #payment-type-cc {
          margin-bottom: 16px;
        }
        .payment-type-panel {
          .payment-type {
            margin-right: 14px;
            label {
              margin-left: 7px;
              img {
                vertical-align: middle;
              }
            }
          }
        }
      }
    }
  }
  .right.checkout__sidebar {
    .return-link-container {
      margin-bottom: 20px;
    }
    #offer-code-panel {
      height: auto;
      .offer-code-panel__content {
        .offer_code_form_container {
          input.form-submit {
            position: relative;
            top: 0;
          }
          #one-offer-only {
            font-style: normal;
            display: block;
            margin: 18px 0 14px 0;
          }
        }
      }
    }
    .tax.value {
      line-height: 19px;
    }
  }
  ol.checkout-progress {
    li {
      float: left;
      margin: 24px 0 0 24px;
      width: 175px;
      list-style-type: decimal;
    }
    .shipping .checkout-progress__shipping,
    .review .checkout-progress__review,
    .billing .checkout-progress__billing,
    .confirm .checkout-progress__confirm {
      font-weight: bold;
    }
  }
  .account-utilities {
    &__section {
      background: $color-gray-light-alt;
      padding: 16px 20px;
    }
    &__header {
      font-family: $brandon-text-bold;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      text-indent: 0;
      margin: 4px 0 10px 0;
      padding-bottom: 17px;
      border-bottom: 1px solid $color-black;
    }
  }
  .utq_banner {
    display: none;
  }
}

#confirm {
  #print-order {
    margin-top: 20px;
  }
  .viewcart.checkout {
    ol.checkout-progress {
      .checkout-progress {
        &__confirm {
          font-weight: bold;
        }
      }
    }
  }
}

#payment {
  .viewcart.checkout {
    ol.checkout-progress {
      .checkout-progress {
        &__billing {
          font-weight: bold;
        }
      }
    }
  }
}

#samples {
  .samples-page {
    #samples-panel {
      .samples-buttons.bottom {
        display: block;
      }
      .left.checkout__content {
        .samples {
          .product.sample-cell {
            ul.sample-sku-list {
              li.sample-sku-item {
                .sample-select-checkbox {
                  display: block !important;
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }
    .product {
      min-height: 482px;
      &.first {
        margin-left: 0;
      }
    }
  }
}

#shipping {
  #address_form_container {
    margin-bottom: 17px;
    .address-overlay-billing,
    .address-overlay-shipping {
      .form_element {
        margin-bottom: 6px;
      }
      .title_container,
      .phone_label_container {
        margin-bottom: 10px;
      }
      input[type='text'] {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  .address_form_container {
    a.selectBox {
      margin-bottom: 20px;
    }
  }
  .viewcart.checkout {
    ol.checkout-progress {
      .checkout-progress {
        &__shipping {
          font-weight: bold;
        }
      }
    }
  }
}

#recommended-products-panel {
  .recommended-product-items {
    .recommended-item {
      position: relative;
      padding-bottom: 60px;
      .column.item_info {
        position: relative;
        .product_name {
          height: 45px;
        }
        .product_subname {
          height: 20px;
        }
      }
      .add_button {
        position: absolute;
        bottom: 0;
      }
    }
  }
}

#review {
  #review-address-info {
    .transaction-panel-details {
      .transaction-item.trans_detail_item {
        h4.checkout__subtitle {
          letter-spacing: 0.13em;
        }
      }
    }
  }
  .viewcart.checkout {
    ol.checkout-progress {
      .checkout-progress {
        &__review {
          font-weight: bold;
        }
      }
    }
  }
}

.newsletter-info {
  .newsletter-info__fieldset {
    .newsletter-info__text--provid {
      float: left;
      width: 100%;
    }
    .newsletter-info__item {
      width: 100%;
    }
  }
  &__header {
    text-indent: 0;
  }
}

.sms_promotions {
  margin-bottom: 10px;
  margin-left: 20px;
  .sms_options_container {
    margin: 10px 0;
    .phone_container {
      input {
        margin-top: 5px;
      }
    }
  }
}

.touch select {
  min-width: 100%;
}

.waitlist-iframe-wrapper {
  width: 100%;
  height: 95%;
}

.tiny-waitlist-overlay {
  .waitlist_header {
    line-height: 29px;
  }
}

.click_and_collect_map {
  .overlay-content {
    .map-container {
      .address-map-location-panel,
      .map {
        .location {
          .image {
            display: none;
          }
        }
      }
    }
  }
}

.checkout-co-enabled {
  .checkout {
    &__sidebar {
      .order-summary-panel {
        &__title {
          margin-top: 20px;
        }
      }
    }
    &__content {
      .viewcart-header {
        &.checkout-header {
          margin: 0;
        }
      }
    }
  }
}

@include breakpoint($medium-up) {
  .checkout-co-enabled {
    .checkout {
      #recommended-products-panel {
        .content {
          .recommended-product-items {
            .recommended-item {
              .add_button {
                .btn {
                  white-space: normal;
                  word-wrap: break-word;
                  height: 62px;
                }
              }
              .description {
                .info {
                  .formatted_price {
                    margin-top: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.enhanced-delivery-page {
  .column.left #checkout_complete {
    .wrapper {
      #payment-info-panel {
        .cc-header {
          display: none;
        }
        #payment-type-cc {
          .payment-type {
            border-top: 1px solid #999999;
            img {
              margin-left: -5px;
            }
          }
        }
      }
    }
  }
}