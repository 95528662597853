header.site-header {
  .site-utils__links {
    .lpStaticButton {
      a {
        img {
          top: 0px;
        }
      }
    }
  }
}

.section-customer-service-contact-us {
  #contact-about-content {
    .customer-service-contact-form__category {
      padding: 1em 0;
      letter-spacing: 1px;
      line-height: 40px;
      .online_product_tech_service {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
      }
    }
  }
}

.site-footer-contact {
  .site-footer-contact__item.customerservice_width {
    width: 100%;
  }
  @media (min-width: 768px) {
    .site-footer-contact__item.customerservice_width {
      width: 50%;
      a {
        margin-left: 20%;
      }
    }
  }
}

.responsive-tabs {
  .resp-tabs-list {
    .bv_ask_tab {
      display: none;
    }
  }
}

.quickshop__container {
  .product--full {
    .product__rating {
      div {
        &.t-desktop {
          display: none;
        }
      }
      span {
        div {
          &.t-desktop {
            display: inline;
          }
        }
      }
    }
  }
}

.product__details {
  .product__rating {
    #BVRRSummaryContainer {
      width: 100%;
      margin-top: 7px;
      margin-bottom: 20px;
    }
    #BVQASummaryContainer {
      display: none;
    }
  }
}

.customer-service {
  .customer-service-quick-info {
    .customer-service-quick-info__list-item {
      margin-left: 14%;
    }
  }
}

.site-email-sms-signup {
  .site-email-sms-signup__header {
    width: 690px;
  }
}

.telluride-collection {
  .product_collection_hero__content-item {
    position: relative !important;
  }
}

.field-collection-slides {
  .sunset_pink {
    overflow: hidden !important;
  }
}

.sunset_pink {
  h1 {
    &:before {
      font-family: $miller-banner-light;
      font-size: 80px;
      text-align: left;
      line-height: 0.8em;
      font-weight: 100;
      letter-spacing: 0px;
      content: url(/media/export/cms/collections/sunset_pink/SunsetPink-hero.jpg);
    }
  }
  .product_collection_hero {
    h1 {
      color: $color-white !important;
      width: 508px;
      height: 188px;
    }
  }
}

.popup-offer {
  &__sign-up-form {
    .popup-offer__footer {
      margin-bottom: 12px;
    }
  }
}

.offer-code-panel {
  .offer_code_form_container {
    em#one-offer-only {
      span {
        a {
          margin-top: 33px;
        }
      }
    }
  }
}

.customer-service-menu {
  .menu__link {
    height: auto;
  }
}

.lp_radio_button,
.lp_checkbox_button {
  input {
    display: none;
  }
}

.section-esearch {
  .search-form--results {
    input.search-form__submit {
      margin-#{$ldirection}: 0;
    }
    .search-form__results-count {
      line-height: 2.5;
      #{$rdirection}: 181px;
    }
  }
}

#footer_sticky_chat {
  min-height: 65px;
}

#bt_notification {
  .bt-content {
    .btn-wrap {
      a.btn {
        font-family: $brandon-text-bold;
        cursor: pointer;
      }
    }
  }
}

.product-full {
  &__container {
    .product-full-shades__content {
      @include breakpoint($medium-up) {
        overflow-x: hidden;
      }
    }
    .product-full__data-heading {
      &-label {
        @include breakpoint($medium-up) {
          width: 42%;
        }
      }
    }
  }
}

@if $virtual-appointment {
  .appt-book {
    .appointment-booking-main-collection__header-content {
      .virtual__appointment {
        &--btn {
          @include breakpoint($medium-up) {
            padding: 0 45px;
            margin: 20px 0;
          }
        }
      }
    }
  }
}

.viewcart {
  .payments-icon {
    .logo {
      &_visa {
        background: url('/media/images/checkout/payment_logo/BB-Visa-logo.svg') no-repeat;
      }
      &_mc {
        background: url('/media/images/checkout/payment_logo/BB-MC-logo.svg') no-repeat;
      }
    }
  }
}
