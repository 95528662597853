@charset "UTF-8";

.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.clearfix:after {
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

select::-ms-expand {
  display: none;
}

.site-header {
  .lpStaticButton {
    width: auto;
    margin: 0px;
    td {
      border: none;
      padding: 0px;
    }
    .lpPoweredBy,
    .lpEmtStarRating {
      display: none;
    }
    a img {
      position: relative;
    }
  }
  .site-utils__inner {
    padding: 0 15px 0 15px;
    .site-utils__nav {
      .menu__link--lvl-1 {
        padding: 0 3px;
        letter-spacing: 0.16em;
      }
    }
    .site-utils__links {
      .site-utils__right {
        ul.site-utils__menu {
          li.site-utils__item {
            .site-utils__dropdown {
              .sign-in.is_signed_in {
                ul.account-links__container {
                  li.account-links__logout {
                    letter-spacing: 0.16em;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.site-footer-contact {
  span.icon-email,
  span.icon-livechat {
    float: left;
  }
}

.cart_overlay {
  .cart-confirm-wrapper {
    .cart-products {
      .prod {
        padding: 17px 0;
      }
    }
  }
}

#bt_notification {
  div.bt-content {
    border-color: $color-light-gray !important;
    border-width: 1px !important;
    a {
      text-decoration: none !important;
      &.site-utils__dropdown__close {
        width: 19px;
        top: -22px;
        right: 7px;
      }
    }
    .bt-find-more {
      line-height: 2em;
      font-size: 14px;
      border-bottom: 1px solid $color-pink;
    }
  }
  div.bt-bottom-right {
    width: 337px !important;
    bottom: 10px;
  }
}

.customer-service-section {
  #landing-email {
    background: none;
    border-bottom: 1px solid $color-gray-alt;
  }
  #landing-faqs {
    border-right: 1px solid $color-gray-alt;
    border-top: none;
  }
  #landing-call {
    border-bottom: 1px solid $color-gray-alt;
  }
  @media (max-width: 768px) {
    .customer-service-landing-block {
      a {
        bottom: 40px;
      }
    }
  }
}

.section-customer-service-returns {
  .customer-service-menu {
    .menu__link {
      height: auto;
    }
  }
}

.makeup-lessons-landing {
  .makeup-lessons__tout-wrapper {
    .makeup-lessons__tout-image-header {
      span {
        font-size: 27px !important;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .appt-book-overlay.appt-book-datepicker-overlay {
    .overlay-content-container {
      width: 750px;
      left: 63%;
      top: 100px;
    }
  }
  .section-book-appointment {
    .appt-book-location-selector {
      .form-container {
        padding: 0 20px;
        input {
          margin: 30px auto;
          float: none;
        }
      }
    }
    .appointment-select {
      .appointments-container {
        .booking-step.step1 {
          .selects-container {
            .select-container {
              width: auto !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .section-book-appointment {
    .appt-book-location-selector {
      .form-container {
        padding: 0 20px;
        input {
          margin: 30px auto;
          float: none;
        }
      }
    }
  }
}

.appt-book {
  #appointment-book-sections {
    .appt-book-section-content {
      &.service-select {
        .book-now-bar {
          max-width: 455px !important;
        }
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        .registration__terms.error,
        .registration__oab-email-promotions.error {
          color: $color-red;
        }
      }
    }
  }
  .location-select-section {
    p {
      line-height: 1.45em;
    }
    b {
      font-size: 20px;
    }
  }
}

.store-locator__hero {
  .outer-wrap {
    margin: 0 auto;
    max-width: 1024px;
    position: relative;
    .store-locator__form-container {
      width: 100%;
      .store-locator__locate.geo_search {
        float: left;
        width: 310px;
      }
      .store-locator-either-or {
        float: left;
        width: 20px;
      }
      .store-search-controls {
        float: left;
        .form_element.distance_container {
          a.distance.selectbox {
            margin: 10px 0 0 5px !important;
            float: right !important;
            width: 205px !important;
          }
        }
        .search-submit {
          position: absolute;
          top: 62px;
          right: 0px;
        }
      }
    }
    .locator_error_messages {
      width: 75%;
      padding-left: 70px;
    }
  }
}
/**  power-review  **/
#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-helpful {
        &-yes {
          .pr-helpful-count {
            &:before {
              content: 'Sí';
              visibility: visible;
            }
          }
        }
        &-no {
          .pr-helpful-count {
            &:before {
              content: 'No';
              visibility: visible;
            }
          }
        }
      }
      .pr-accordion-content {
        dt {
          padding-right: 25px;
        }
      }
    }
  }
}

.write_a_review__container {
  #pr-write {
    .p-w-r {
      #pr-war-form {
        .input-add-tag {
          width: 90%;
        }
        .pr-preocupacionesdelapiel-form-group,
        .pr-beneficiosdelproducto-form-group {
          .pr-control-label {
            float: left !important;
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.spp {
  .product {
    .product__title {
      .product__flag {
        display: inline-block;
      }
    }
  }
}

.mpp {
  .product-grid {
    .product-thumb {
      .product__flag {
        display: inline-block;
      }
    }
  }
}

.product-full-shades {
  .product-full-shades__detail {
    &-shadename {
      margin-right: 10px;
    }
    .product-full-shades__detail-shadedesc {
      width: auto;
    }
  }
}

.spp-product-layout {
  .sticky-add-to-bag {
    &__cta {
      .product {
        &__button--add-to-bag {
          width: auto;
        }
      }
    }
  }
}
