.registration,
.sign-in-page,
.welcome15-overlay {
  #loyalty_popup_offer_form {
    .welcome-overlay__checkbox {
      &__terms:checked + .label::before {
        background-color: inherit;
      }
      &.welcome-overlay__checkbox--second,
      &.welcome-overlay__checkbox--third {
        display: none;
      }
      .col-40 {
        @include breakpoint($small-only) {
          width: 100%;
        }
      }
    }
  }
  #cboxContent {
    .welcome-overlay {
      &__header {
        font-size: 36px;
      }
      &__copy {
        font-size: 17px;
      }
      &__checkbox {
        .label__content {
          font-size: 13px;
        }
      }
      &__label {
        font-size: 12px;
        margin-top: 30px;
        @include breakpoint($xsmall-down) {
          margin-top: 90px;
        }
      }
    }
  }
}

.new-account__item {
  &.checkbox__content {
    margin-bottom: 10px;
  }
  &.registration-notice {
    clear: both;
    margin-top: 0;
  }
  &_communication_consent {
    margin-left: 20px;
  }
  &__communication_sms_block {
    position: relative;
    overflow: hidden;
    min-height: 40px;
    .new-account__item__sms_promotions {
      float: left;
    }
    .new-account__item_phone1 {
      float: left;
      margin-left: 20px;
      input {
        margin: 0;
      }
    }
  }
}

.marketing__enrollment {
  #loyalty_elist_form {
    .marketing__enrollment {
      &__accept__terms {
        .label-content {
          width: calc(100% - 20px);
          max-width: 547px;
        }
      }
      &__checkbox_container {
        margin-bottom: 20px;
        &__sms {
          min-width: 120px;
          margin-bottom: 20px;
        }
      }
      &__phone_number {
        position: absolute;
      }
      &__subheader--gray {
        color: $color-grey-footer;
      }
    }
  }
}

.confirm-panel {
  .checkout__panel--heading {
    font-weight: 700;
    text-transform: uppercase;
    margin: 20px 0 0;
  }
  .checkout__panel__guest__signup__text {
    margin-bottom: 10px;
  }
  .create-password {
    margin-top: 10px;
  }
  .loyalty__section__content__options__title {
    font-size: 14px;
    font-weight: 700;
    margin: 15px 0;
    letter-spacing: 0.2em;
  }
  .loyalty__section__content__options__join {
    margin-top: 15px;
  }
  .account-page__gdpr_communication {
    .registration__checkbox {
      margin: 5px;
      &.sms-promotions {
        float: left;
      }
    }
    .phone_number_container {
      float: left;
      margin-left: 15px;
    }
  }
  .privacy_policy_container {
    clear: both;
  }
}
// dirty fix to bug with second scrollbar showing when tooltipster icon is hovered
// clean solution would be to update tooltipser js plugin library to v4 at least
// https://github.com/iamceege/tooltipster/issues/57
body#viewcart {
  overflow-x: initial !important;
}

.checkout__sidebar {
  .checkout-panel--loyalty-points {
    border: none;
    margin: 0;
    padding: 20px;
    background: $color-gray-background;
    @include breakpoint($medium-up) {
      padding: 0 20px 20px;
    }
  }
  .checkout-panel__content {
    position: relative;
  }
  .loyalty-points-content__summary__non-member-content {
    padding: 20px 0;
    margin: 0;
    border-bottom: 1px solid $color-black;
    border-top: 1px solid $color-black;
  }
  .loyalty-points-content__summary .loyalty-info-icon {
    top: 15px;
    right: 0;
  }
}

.site-utils__item--loyalty--join {
  .home_gnav_loyalty_join {
    letter-spacing: initial;
  }
}

.js-sticky-header--animate {
  @include breakpoint($medium-up) {
    #node-1224 {
      .site-utils__menu--v2 {
        max-width: 400px;
      }
    }
  }
}

.color-black {
  color: $color-black;
}

.loyalty__panel__points__info-section {
  position: relative;
  @include breakpoint($medium-up) {
    height: 151px;
  }
  .loyalty__panel__points__info-link {
    position: absolute;
    bottom: 17px;
    left: 10px;
    .info-link--hyper-link {
      display: block;
      margin-bottom: 14px;
    }
  }
}

#loyalty-offer-code-panel {
  margin-top: 15px;
  border-bottom: 1px solid $color-black;
  .loyalty-offer-panel__title {
    border: none;
    padding: 0 25px;
    &.open {
      .title-icon {
        @include icon('arrow_down', 'after', false, false, 14px);
        transform: rotate(180deg);
      }
    }
  }
  .title-icon {
    @include title-icon;
    #{$rdirection}: 20px;
    @include breakpoint($medium-up) {
      #{$rdirection}: -5px;
    }
    &::after {
      font-weight: bold;
    }
  }
  .offer-code-wallet {
    &__code-info--applied {
      width: 200px;
      margin: auto;
    }
    &__code__description {
      width: 80%;
      margin: auto;
      text-align: center;
      &-title {
        font-weight: bold;
        margin: 5px 0;
      }
    }
    &__button--apply {
      margin: 5px 0;
    }
  }
  .loyalty-points-content {
    &__summary__non-member-content {
      font-weight: 700;
      text-align: center;
      display: inline-block;
      margin-bottom: 20px;
      width: 80%;
    }
    &__summary__member-content {
      text-align: center;
      margin-bottom: 5px;
    }
  }
  .loyalty-offer-points-panel__content {
    text-align: center;
  }
  .loyalty-info-icon {
    position: relative;
    font-size: 9px;
    border: 1px solid $color-gray-dark;
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    line-height: 1.4;
  }
  .loyalty-tooltip-content {
    display: none;
  }
}

section.loyalty-panel {
  display: none;
}

.confirmation-panel {
  @include breakpoint($medium-down) {
    .loyalty-content {
      padding: 0 10px 30px;
    }
  }
  .signin-panel--lr2021 {
    .lr2021__confirmation_cta {
      .lr2021__cta {
        background-color: $color-black;
      }
    }
  }
}

.text-underlined {
  text-decoration: underline;
}

.marketing__benefits__section__row__content {
  @include breakpoint($medium-up) {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }
}

.marketing__benefits__section__row__header {
  @include breakpoint($medium-up) {
    align-self: center;
  }
}

.marketing__benefits__section__row__tier .checkmark:only-child {
  @include breakpoint($medium-up) {
    height: 100%;
    min-height: 26px;
    background-position: 50%;
  }
}

#form--profile_preferences--field--MOBILE_NUMBER {
  min-width: 180px;
}

// ipad fixes:
.loyalty__panel__offers__list-container,
.loyalty__panel__offers__sku {
  .slick-prev,
  .slick-next {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      border-radius: 100%;
      width: 45px;
      height: 45px;
    }
    &::before,
    &::after {
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        right: auto;
        transform: translate3d(-50%, -50%, 0);
        width: auto;
        height: auto;
      }
    }
  }
  .slick-next {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      left: auto;
      right: 10px;
    }
  }
}

.loyalty__panel__offers__sku__product__image-container {
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    height: 436px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    height: 270px;
  }
}

.domain-es .marketing__enrollment__header,
.domain-es .marketing__benefits__header {
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 8vw;
  }
}

.section-loyalty-program {
  .info-msg {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      left: 50% !important; // !importants overrides inline sytles added by js
      transform: translate3d(-50%, 0, 0);
    }
  }
  .info-msg__arrow.top-right-arrow {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      left: 50%;
    }
  }
}

.account-page {
  & &__non__loyalty__container {
    height: auto;
    background-position: bottom;
    @if ($lr2021) {
      background-size: contain;
      background-image: url('/media/export/cms/loyalty/account_loyalty_banner_pc_bg_lr2021_vertical.jpg');
      background-color: $color-black;
      padding-bottom: 90%;
    } @else {
      background-size: cover;
      background-color: $color-gray;
    }
  }
  & &__non__loyalty__loyalty__text {
    width: 100%;
  }
  &__non__loyalty__loyalty__text--popup-trigger {
    padding-top: 0;
    margin-top: 20px;
    text-decoration: underline;
    color: $color-white;
  }
  &__content {
    .loyalty__panel__offers {
      .loyalty__panel__offers__title {
        font-size: 20px;
      }
      &__sku {
        .loyalty__panel__offers__sku__title {
          font-size: 15px;
        }
      }
    }
    .loyalty__panel__transactions__title {
      font-size: 15px;
    }
  }
  .loyalty-page__content {
    .loyalty__panel__points__info-link {
      .loyalty__panel__points__info-link {
        display: inline-block;
      }
    }
  }
  .loyalty__panel__points__info-section {
    .loyalty__panel__points__info-link {
      width: 94%;
    }
  }
}

.profile-page {
  .profile-page__content {
    .loyalty-membership-section__fieldset {
      padding-#{$rdirection}: 20px;
    }
  }
}

.account {
  .account__section {
    .profile-page {
      .profile-page__content {
        .loyalty-membership-section__fieldset {
          .form-submit {
            width: 93%;
          }
        }
      }
    }
    .account-mobile__non__loyalty {
      padding: 10px 0 0 0;
      .account-mobile__non__loyalty__image {
        margin-top: 20px;
      }
    }
  }
}

#loyalty__content {
  .content {
    .earning-points__header {
      font-size: 20px;
    }
  }
}

.clearfix {
  .loyalty__panel__points__info-rewards-container-link {
    display: none;
  }
}
.loyalty__panel__points__info-container {
  .loyalty__panel__points__info-section {
    .loyalty__panel__points__info-link {
      @include breakpoint($small-only) {
        #{$ldirection}: 11px;
        width: 97%;
      }
    }
  }
}

.non__loyalty__panel__join.local-grid {
  color: $color-white;
  @mixin margin-common-non-lyl-panel {
    margin-top: 10px;
    a {
      color: $color-white;
      text-decoration: underline;
    }
  }
  @include breakpoint($medium-up) {
    width: 95%;
  }
  input[type='text'] {
    width: 50%;
    @include margin-common-non-lyl-panel;
  }
  .label {
    @include margin-common-non-lyl-panel;
    display: block;
  }
  .accept-privacy-info {
    @include margin-common-non-lyl-panel;
  }
}

.account-mobile {
  &__non__loyalty__disclaimer {
    margin-bottom: 20px;
  }
}
