.content {
  #bt_notification {
    .bt-content {
      .bt-cookie-section {
        padding: 10px;
      }
    }
  }
}

#bt_settings {
  .bt-content {
    padding: 28px;
    .bt-intro {
      h2 {
        border: 0;
        font-size: 14px;
        padding: 0;
      }
      .bt-intro-copy {
        p {
          font-size: 14px;
          letter-spacing: 1px;
          line-height: 20px;
        }
      }
    }
    .slide {
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 20px 0 20px 20px);
      }
    }
    .bt-privacy-info {
      .bt-privacy-will,
      .bt-privacy-willnot {
        font-size: 14px;
        letter-spacing: 1px;
      }
    }
  }
}
