.enhanced-delivery-page {
  .shipping-address-info {
    .state_container {
      a.selectBox {
        &::before {
          #{$rdirection}: 15px;
          border: 5px solid transparent;
          border-top-color: $color-black;
          content: '';
          pointer-events: none;
          position: absolute;
          top: 22px;
        }
        .selectBox-arrow {
          display: none;
        }
      }
    }
  }
  .pg_wrapper {
    .column.left {
      .wrapper {
        #checkout_shipping {
          #billing-info-panel {
            .billing-address {
              margin: 0;
            }
          }
          .form_element {
            .selectBox {
              @include swap_direction(padding, 18px 20px 18px 15px);
              line-height: 1.1;
              text-transform: none;
            }
          }
        }
      }
    }
  }
  #payment-info-panel {
    .payment-type {
      label {
        padding-top: 15px;
      }
    }
  }
  #delivery-options {
    .delivery-options-title {
      display: none;
    }
  }
  #shipping-container {
    .shipping-msg {
      .express-lable {
        display: none;
      }
    }
  }
  .offer-code-panel {
    .offer_code_form_container {
      #one-offer-only {
        span a {
          font-style: normal;
        }
      }
    }
  }
  &#payment {
    .column.right {
      &.checkout__sidebar {
        #shipping-panel {
          #review-address-info {
            .transaction-panel-details {
              .transaction-item {
                &.gift_container {
                  .change_link {
                    display: block !important;
                    visibility: visible;
                  }
                }
                &.shipping_container.transaction-details-content {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
