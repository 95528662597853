.account {
  #address_form_container {
    margin-bottom: 17px;
    .address-overlay-shipping {
      .form_element {
        margin-bottom: 6px;
      }
      .title_container,
      .phone_label_container {
        margin-bottom: 10px;
      }
      input[type='text'] {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  .profile-page__content {
    #profile_preferences {
      .profile-info {
        .profile-info__item.profile-info__required-container {
          float: none;
          padding: 0;
        }
      }
    }
    .profile-info__fieldset {
      .profile-info__item.title_container {
        float: none;
        width: 100%;
      }
    }
    .newsletter-info__fieldset {
      .newsletter-info__item {
        float: none;
      }
    }
    .profile-info__item {
      .password_help_text {
        padding-top: 5px;
      }
    }
  }
  #address_form_container {
    .address-overlay-shipping {
      .form_element {
        &.title_container {
          width: 100%;
        }
      }
    }
  }
}

.optional-info {
  .optional-info__fieldset {
    .optional-info__item {
      float: none;
    }
  }
}

a.selectBox {
  &.error {
    border: 1px solid #ff0000;
  }
}

.order-details-page.signin-page {
  max-width: 1024px;
  margin: 40px auto;
  .account-utilities {
    float: left;
    margin-right: 32px;
  }
  .order-details-page__content {
    width: auto;
    overflow: hidden;
    padding-bottom: 1px;
    .transaction-panel-details {
      margin-top: 32px;
      .trans_detail_item {
        float: left;
        margin: 0 28px 28px 0;
        position: relative;
        width: 225px;
        word-wrap: break-word;
      }
    }
    .order-products {
      .cart-item.product {
        .cart-item__desc {
          @media (min-width: 768px) {
            width: 31%;
            float: left;
          }
        }
      }
      .cart-header {
        .qty {
          width: 26%;
        }
      }
    }
  }
}

.account__panel-title {
  font-family: $brandon-text-bold;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-indent: 0;
  margin: 4px 0 10px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid $color-black;
}

.signin-page {
  max-width: 1024px;
  margin: 40px auto;
}

.return-user__item,
.new-account__item,
.request-form__item,
.sent-info__text,
.password-reset__item {
  margin-bottom: 0.8em;
}

.password-reset__item {
  .field {
    display: block;
    width: 300px;
  }
}

.password-request-page {
  &__header {
    text-indent: 0;
    padding-left: 20px;
  }
}

.account-page {
  &__content {
    .section-header {
      &:before {
        background: none;
      }
    }
    .account-profile {
      &__newsletter {
        .newsletter_text {
          margin-bottom: 0;
        }
      }
      &__email {
        word-wrap: break-word;
      }
    }
  }
}

#address_form_container {
  .address-overlay-shipping {
    .address {
      .default_ship_container {
        width: 100% !important;
      }
    }
  }
  #address {
    position: relative;
  }
}

.address_form_container {
  .title_container {
    margin-bottom: 10px;
  }
}

.past-purchases {
  &__shopping {
    margin: 30px 0 0 0px;
  }
}

@media screen and (max-width: 768px) {
  section.favorites-page__content {
    .favorites-page__header {
      font-size: 20px;
      letter-spacing: 0.15em;
    }
  }
}

.sample {
  .cart-item {
    &__qty-label {
      display: block;
    }
  }
}

.site-utils__dropdown--account {
  .sign-in {
    .registration {
      form {
        input {
          width: 100%;
        }
      }
      &__show-pass {
        margin: 5px 0 0 0;
      }
      &__terms {
        margin-top: 10px;
      }
    }
  }
}

.profile-pic-upload {
  .profile-pic-upload__form-wrapper {
    .upload-file {
      .upload-file__value {
        right: 20px;
        word-wrap: break-word;
        left: 200px;
      }
    }
  }
}

.create-wishlist {
  &__name {
    width: 58%;
  }
}

.product__flag {
  display: none;
}

#colorbox {
  .address-overlay-billing {
    .title_container,
    .js-state-select {
      margin-bottom: 6px;
    }
    .form_element {
      .field {
        width: 100% !important;
        margin-bottom: 10px !important;
      }
    }
  }
}

.favorites__instruction-step {
  text-align: center;
  &-number,
  &-title {
    display: inline;
    margin-bottom: 15px;
    color: $color-black;
  }
  &-desc {
    margin-top: 15px;
  }
}
